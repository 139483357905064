import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/base.css'
import Vconsole from 'vconsole'

import './directive'

import 'amfe-flexible'

// 导入全局组件 ArticleItem 并 全局注册（今后在任意vue文件直接使用）

import ArticleItem from './components/ArticleItem.vue'

import { formatTimeAgo } from './utils/date'

import {
  NavBar, Form, Cell, List, ActionSheet,
  Field, Button, Icon, PullRefresh,
  Tabbar, TabbarItem, Tabs, Tab, Dialog,
  Popup, Row, Col, Badge, Search, Divider,
  Tag, CellGroup, Image, DatetimePicker, Stepper, Uploader, RadioGroup, Radio, Checkbox, CheckboxGroup
} from 'vant'

// 刚才少些了这么一行代码，需要把 permission.js 文件 导入 main.js，让其生效
import './permission'

Vue.component(ArticleItem.name, ArticleItem)

// 把公共函数 加入 到 vue的原型上，
// 原理就是：每个vue组件都是继承自Vue的实例，可以直接访问vue原型上的一切属性和方法

Vue.prototype.formatTimeAgo = formatTimeAgo

Vue.use(DatetimePicker)
Vue.use(Image)
Vue.use(Tag)
Vue.use(CellGroup)
Vue.use(Divider)
Vue.use(Search)
Vue.use(Badge)
Vue.use(Row)
Vue.use(Col)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(ActionSheet)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Cell)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Stepper)
Vue.use(Uploader)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') { // 测试和开发打开，生产不能打开
  let vConsole = new Vconsole()
  Vue.use(vConsole)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
