import request from '../utils/request'

// 登录页面 - 登录接口
export const loginAPI = (data) => {
  return request({
    method: 'POST',
    url: 'api/auth/appLogin',
    data
  })
}

// 登录页面 - 登录接口
export const logoutAPI = () => {
  return request({
    method: 'delete',
    url: '/api/auth/logout'
  })
}
