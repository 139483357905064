import request from '../utils/request'

// 添加拜访
export const arsVisitAddPlanApi = (data) => {
  return request({
    method: 'POST',
    url: '/api/arsVisitPlan/addPlan',
    data
  })
}

// 拜访计划列表
export const arsVisitPlanListApi = () => {
  return request({
    method: 'get',
    url: '/api/arsVisitPlan/planList',
  })
}

// 拜访计划详情map
export const arsVisitPlanDetailApi = (planId) => {
  return request({
		url: `/api/arsVisitPlan/planDetail?planId=${planId}`,
    method: 'get',
  })
}
