import Vue from 'vue'
import Vuex from 'vuex'
import { loginAPI } from '@/api/login'
import Storage from '@/utils/storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  // 开启严格模式 —— 一切数据 的修改 只能 通过 mutation 进行
  strict: true,
  state: {
    usToken: Storage.get('usToken'),
    userInfo: {
      name: '',
      photo: Storage.get('photo')
    },
    myUserInfo: Storage.get('myUserInfo') ? JSON.parse(Storage.get('myUserInfo')) : {},
    storeNum: localStorage.getItem('storeTotal') || ''
  },
  mutations: {
    setToken (state, tokenStr) {
      state.usToken = tokenStr
      Storage.set('usToken', tokenStr)
    },
    setUSERINFO(state, userobj) {
      state.myUserInfo = userobj
      Storage.set('myUserInfo', JSON.stringify(userobj))
    },
    setUserInfo (state, userObj) {
      state.userInfo = userObj
    },
    setUserName (state, val) {
      state.userInfo.name = val
    },
    setUserPhoto (state, val) {
      state.userInfo.photo = val
      Storage.set('photo', val)
    }
  },
  actions: {
    async asyncLoginAction (store, formLogin) {
      // 1. 发送登录请求
      const resp = await loginAPI(formLogin)
      console.log('loginAPI', resp)
      const { token, user } = resp.data
      // 2. 提交到mutation - 同步token 和 refreshToken
      store.commit('setToken', token)
      store.commit('setUSERINFO', user)
    }
  },
  modules: {
  }
})

export default store
