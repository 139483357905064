import request from '../utils/request'

// 我的-拜訪
export function dataScopeUserListApi (data) {
  return request({
    url: `/api/users/dataScopeUserList`,
    method: 'get'
  })
}
// 我的-拜訪-列表
export function arsVisitListPageApi (data) {
  return request({
    url: `/api/arsVisit/appVisitListPage`,
    method: 'post',
    data: data
  })
}
