import moment from 'moment'
moment.locale('zh-cn')

// 转换时间 - 多久之前
const formatTimeAgo = targetDate => {
  return moment(targetDate).from(new Date())
}

export {
  formatTimeAgo
}
