<template>
  <div class="article-item__container">
   <!-- 一条文章单元格 -->
    <van-cell border @click="toDetails(item)">
      <!-- 标题区域的插槽 -->
      <template #title>
        <div class="channel-item">
          <img v-if="item.imgUrl && item.imgUrl.split(',')[0]" class="thumb" :src="item.imgUrl.split(',')[0]" alt="" @click.stop="imgPreview(item.imgUrl.split(',')[0])">
          <img v-else class="thumb" src="@/assets/images/icon_noimg.png" alt="">
          <div class="right-info">
            <div class="channel-title">
              <!-- <img class="icon-img" src="@/assets/images/icon_type_channel.png" alt=""> -->
              <span class="channel-name txt-ellipsis">{{item.customerName}}</span>
            </div>
            <div class="channel-status">
              <div style="display: flex; align-items: center;">
                <span class="customer-level">{{item.customerLevel}}</span>
                <div v-for="(statusItem, statusIndex) in statusList" :key="statusIndex" class="status-item">
                  <!-- <span v-if="item[statusItem.value] > 0" class="status-checked"><van-icon name="success" /></span>
                  <span v-else class="status-checke"></span> -->
                  <div v-if="statusItem.value === 'mainMaterielCount'" class="tb-img">
                    <img v-if="item[statusItem.value] > 0" src="@/assets/images/icon_ars_keymaterial_ok.png" alt="">
                    <img v-else src="@/assets/images/icon_ars_keymaterial.png" alt="">
                  </div>
                  <div v-else-if="statusItem.value === 'protoCount'" class="tb-img">
                    <img v-if="item[statusItem.value] > 0" src="@/assets/images/icon_ars_sample_ok.png" alt="">
                    <img v-else src="@/assets/images/icon_ars_sample.png" alt="">
                  </div>
                  <div v-else-if="statusItem.value === 'materielCount'" class="tb-img">
                    <img v-if="item[statusItem.value] > 0" src="@/assets/images/icon_ars_material_ok.png" alt="">
                    <img v-else src="@/assets/images/icon_ars_material.png" alt="">
                  </div>
                  <div v-else-if="statusItem.value === 'onSellCount'" class="tb-img">
                    <img v-if="item[statusItem.value] > 0" src="@/assets/images/icon_ars_onsell_ok.png" alt="">
                    <img v-else src="@/assets/images/icon_ars_onsell.png" alt="">
                  </div>
                  <!-- <span class="status-name txt-ellipsis">{{ statusItem.label }}</span> -->
                </div>
              </div>
              <div class="add-route">
                <van-icon size="26" color="#007bff" name="location-o" @click.stop="toVisit(item)" />
                <div v-if="selectMaskStatus" class="select-status-mask" @click.stop="selectCancelMask"></div>
                <div class="route-content" :class="{ 'is-select-info': selectMaskStatus }">
                  <div class="new-plan">
                    <span>New Plan</span>
                    <span class="icon-add-route" @click.stop="addVisitPlan(item)">+</span>
                  </div>
                  <div v-for="(routeItem, routeIndex) in visitplanList" :key="routeIndex" class="plan-list" @click.stop="visitConfirm(routeItem)">
                    <span>{{ routeItem.planName }}</span>
                    <span class="detail-count">{{ routeItem.detailCount }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="time-address-info">
              <div class="last-visit-time">
                <span class="visit-time" v-if="item.lastVisitTime">{{ item.lastVisitTime }}</span>
                <span class="visit-day" v-if="item.apartLastVisitDay">{{ item.apartLastVisitDay }} Day ago</span>
              </div>
              <div class="city-address txt-ellipsis">{{ item.city }}</div>
            </div>
          </div>
        </div>
      </template>
      <!-- label 区域的插槽 -->
      <template #label>
        <div class="label-box">
        </div>
      </template>
    </van-cell>
    <van-dialog v-model="dislogShow" title="New Plan" show-cancel-button @confirm="visitConfirm" :showConfirmButton="false" :showCancelButton="false">
      <van-field class="plan-name-input" v-model="visitPlanName" label="" placeholder="" />
      <div class="dialog-btn">
        <van-button class="cancel-btn" type="primary" @click="cancenlBtn">Cencel</van-button>
        <van-button class="ok-btn" type="info" @click="visitConfirm">OK</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>


import { ImagePreview } from 'vant'
import { arsVisitAddPlanApi, arsVisitPlanListApi } from '@/api/plan'
import { Notify } from 'vant'
export default {
  name: 'ArticleItem',
  props: {
    // 父传子 文章 对象
    item: {
      type: Object,
      required: true
    },
    // 父传子 文章 对象 在数组中的下标
    i: Number,
    // 父传子 控制 x 的 可见性
    isShowCross: {
      type: Boolean,
      default: true
    },
    currentProject: {
      type: Object,
      required: true
    }
  },
  created() {

  },
  data () {
    return {
      statusList: [
        // { label: 'visit', value: 'visitCount' },
        { label: 'Sample', value: 'protoCount' },
        { label: 'Key Material', value: 'mainMaterielCount' },
        { label: 'Material', value: 'materielCount' },
        { label: 'On-Sell', value: 'onSellCount' }
      ],
      visitplanList: [],
      dislogShow: false,
      visitPlanName: '',
      selectMaskStatus: false,
      selectIndex: -1
    }
  },
  methods: {
    selectCancelMask() {
      this.selectIndex = -1
      this.selectMaskStatus = false
    },
    cancenlBtn() {
      this.dislogShow = false
    },
    addVisitPlan(params) {
      console.log('addVisitPlan', params)
      this.dislogShow = true
      // arsVisitAddPlanApi({ planId: '', planName: 'xxx1', customerId: params.id }).then(res => {
      //   console.log(res)
      //   this.visitplanList = res.data
      // })
    },
    visitConfirm(params={}) {
      const data = {
        planId: params.planId || '',
        planName: params.planName || this.visitPlanName,
        customerId: this.item.id
      }
      arsVisitAddPlanApi(data).then(res => {
        console.log('arsVisitAddPlanApi', res)
        this.dislogShow = false
        this.selectMaskStatus = false
      })
    },
    toVisit() {
      if (!this.item.address) {
        Notify({ type: 'warning', message: 'Please complete the address' })
        return
      }
      arsVisitPlanListApi().then(res => {
        console.log('arsVisitPlanListApi', res)
        this.visitplanList = res.data
        this.selectMaskStatus = true
      })
    },
    toDetails(item) {
      this.$router.push(`/visitChannelDetails?art_id=${item.id}&projectId=${this.currentProject.id}`)
    },
    imgPreview(url) {
      ImagePreview([url])
    }
  }
}
</script>

<style scoped lang="less">
/* 标题样式 */
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* label描述样式 */
.label-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 文章信息span */
.label-box span{
  margin: 0 3px;
  &:first-child{
    margin-left: 0;
  }
}

/* 图片的样式, 矩形黄金比例：0.618 */
.thumb {
  width: 113px;
  height: 70px;
  background-color: #f8f8f8;
  object-fit: cover;
}

/* 三图, 图片容器 */
.thumb-box {
  display: flex;
  justify-content: space-between;
}
.van-cell {
  padding: 10px;
  border-bottom: 1px solid #eee;
  overflow: visible;
}

.channel-item {
  display: flex;
  align-items: center;
  .thumb {
    width: 66px;
    height: 70px;
  }
  .right-info {
    flex: 1;
    margin-left: 10px;
    .channel-title {
      display: flex;
      align-items: center;
      .channel-name {
        width: 250px;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .channel-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin: 4px 0 5px 0;
      .status-item {
        width: 30px;
        display: flex;
        align-items: center;
        // border: 1px solid #000;
        .status-checked,
        .status-checke {
          width: 15px;
          height: 15px;
          text-align: center;
          line-height: 15px;
          border: 1px solid #007bff;
          background-color: #007bff;
          color: #fff;
          font-size: 14px;
        }
        .tb-img {
          width: 26px;
          height: 26px;
          >img {
            width: 100%;
            height: 100%;
          }
        }
        .status-checke {
          border: 1px solid #007bff;
          background-color: transparent;
        }
        .status-name {
          flex: 1;
          margin-left: 5px;
        }
      }
      
      .customer-level {
        width: 26px;
        height: 26px;
        margin-right: 16px;
        line-height: 26px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background-color: #2876e3;
      }
    }
    
    .time-address-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      text-align: right;
      .last-visit-time {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 10px;
        text-align: right;
        color: #007bff;
        padding-left: 5px;
        .visit-time {
          // width: 110px;
          margin-right: 5px;
        }
        .visit-day {
          margin-left: 5px;
          text-align: right;
          font-weight: bold;
        }
      }
    }
    .city-address {
        width: 112px;
        font-size: 10px;
        text-align: right;
        color: #7e7e7e;
        font-weight: bold;
      }
  }
  .icon-img {
    width: 20px;
    height: 20px;
  }
  .txt-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.add-route {
  position: relative;
  .route-content {
    display: none;
    position: absolute;
    top: 30px;
    right: 10px;
    width: 157px;
    max-height: 200px;
    overflow: auto;
    z-index: 999;
    padding: 10px;
    box-shadow: 0px 2px 7px 1px rgba(0, 0, 0, 0.16);
    background-color: #ebebeb;
    border-radius: 10px;
    animation: tabIdentifier 0.5s forwards;
    .new-plan {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      .icon-add-route {
        font-size: 26px;
      }
    }
    .plan-list {
      display: flex;
      justify-content: space-between;
      padding: 5px 0px;
      border-bottom: 1px solid #c5c5c5;
      .detail-count {
        color: red;
      }
    }
  }
  .is-select-info {
		display: block;
	}
}
.article-item__container {
  /deep/.van-dialog__content {
    padding: 0 10px
  }
  /deep/.van-dialog__confirm {
    background-color: #1989fa;
    color: #fff;
  }
  .plan-name-input {
    border: 1px solid #b9cbed;
    // height: 30px;
    // line-height: 30px;
    margin: 20px 0;
  }
  .dialog-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .cancel-btn,
    .ok-btn {
      width: 49%;
      border-radius: 10px;
    }
    .cancel-btn {
      background-color: #dce5f6;
      border: none;
      color: #000;
    }
  }
}
.select-status-mask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 93%;
	z-index: 99;
	background-color: rgba(255, 255, 255, 0);
	// background-color: rgba(0, 0, 0, 0.1);
}
@keyframes tabIdentifier {
	0% {
		top: 0px;
	}

	100% {
		top: 30px;
	}
}
</style>
