import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

// const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' +
//   '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ=='

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTXxje5R7h/zB8sdkkz4GZXjVaGSLBJdVtffoLcAo4lZbCv+bl1P11dj5E1NZFnCdwBvX5Tu/U+O4xDZ5zVbL6AgEb8e8FRJkJPuazm/DQKmqXjOn+cYypXAoGbO+BoDnI9kV7lh87dTyWA50UEDMm4Pql35tHIPbeP6i020do8QIDAQAB'

// 加密
export function encrypt (txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}
