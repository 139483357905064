<template>
  <div class="layout-container">
    <div class="main">
      <keep-alive>
        <!-- 二级路由出口 -->
        <router-view/>
      </keep-alive>
    </div>

    <van-tabbar route>
      <van-tabbar-item icon="shop-o" replace to="/layout/home">Store</van-tabbar-item>
      <van-tabbar-item icon="orders-o" replace to="/layout/plan">Plan</van-tabbar-item>
      <van-tabbar-item icon="user-circle-o" replace to="/layout/user">My</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  computed: {
    ...mapState(['storeNum'])
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
  .main {
    margin-bottom: 50px;
  }
</style>
