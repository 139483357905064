import request from '../utils/request'

// 项目
export function projectAppListApi () {
  return request({
    url: '/api/arsProject/appQueryList',
    method: 'get'
  })
}
// 列表
export function customerAppListApi (data) {
  return request({
    url: `/api/arsCustomer/queryAppList`,
    method: 'post',
    data
  })
}
// 详情
export function carsCustomerAppInfoApi (customerId) {
  return request({
    url: `/api/arsCustomer/appInfo/${customerId}`,
    method: 'get'
  })
}

// 拜訪 物料
export function arsMaterielAppApi (projectId) {
  return request({
    url: `/api/arsMateriel/appQueryList?projectId=${projectId}`,
    method: 'get'
  })
}

// 拜访数据
export function arsVisitInfoApi (params) {
  return request({
    // url: `/api/arsVisit/info?customerId=${data.customerId}&projectId=${data.projectId}`,
    url: `/api/arsVisit/info`,
    method: 'get',
    params
  })
}

// 拜訪 样机
export function arsProductlAppApi (projectId) {
  return request({
    url: `/api/arsProduct/appQueryList?projectId=${projectId}`,
    method: 'get'
  })
}

// 图片上传
export function commonFileUploadApi (data) {
  return request({
    url: `/api/commonFile/upload`,
    method: 'post',
    data
  })
}

// 拜访保存
export function arsVisitAddApi (data) {
  return request({
    url: `/api/arsVisit/add`,
    method: 'post',
    data
  })
}
export function dictDetailMapApi (data) {
  return request({
    url: `/api/dictDetail/map?dictName=${data}`,
    method: 'get'
  })
}
export function arsCustomerAdd (data) {
  return request({
    url: `/api/arsCustomer/add`,
    method: 'post',
    data
  })
}
