// 权限管理

// 对路由做守卫 - 路由对全局前置守卫 router.beforeEach((to, from, next) => { })
// 目标：没有登录，很多页面不能访问

import router from './router'

import store from './store'

// 白名单 - 不需要登录就可以访问的页面

const whiteList = ['/login']
router.beforeEach((to, from, next) => {
  const { usToken } = store.state
  // 1. 如果登录了，不再白名单中的路径 ，直接放行
  if (usToken) {
    if (!whiteList.includes(to.path)) {
      next()
    } else {
      // 3. 如果登录了，在白名单的路径，留在原地
      next(false)
    }
  } else {
    // 2. 如果没有登录了，不再白名单中的路径 ，强制跳转到登录页
    if (!whiteList.includes(to.path)) {
      next(`/login?from=${to.path}`)
    } else {
      next()
    }
  }
})
