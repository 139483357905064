<template>
  <div class="home-container">
    <!-- 头部 NavBar -->
    <van-nav-bar fixed>
      <template #right>
        <!-- 搜索页面头部 -->
        <div class="search-header">
          <!-- 搜索组件 -->
          <van-search
            ref="search"
            v-model.trim="paginationList.search"
            placeholder="Name/Zip Code/Address/City"
            background="#007bff"
            shape="round"
            @input="getSearchSuggFn" />
        </div>
      </template>
    </van-nav-bar>
    <div class="van-cell-info">
      <div class="channel-filter" @click="handleVisitFilter">
        <span class="label-name txt-ellipsis" v-if="currentProject.visitLabel">{{ currentProject.visitLabel }}</span>
        <span class="label-name txt-ellipsis" v-else-if="!currentProject.visitLabel && userInfo.user">{{ userInfo.user.nickName }}</span>
        <img class="icon-img" src="@/assets/images/icon_mnew_down.png" alt="">
      </div>
      <div class="channel-filter" @click="handleSortFilter">
        <span class="filter-label txt-ellipsis">{{currentProject.sortFieldVal || 'sort'}}</span>
        <img class="icon-img" src="@/assets/images/icon_mnew_down.png" alt="">
      </div>
      <div class="channel-filter" @click="handleFilter">
        <span class="filter-label txt-ellipsis">{{currentProject.projectName}}</span>
        <img class="icon-img" src="@/assets/images/icon_mnew_down.png" alt="">
      </div>
    </div>

    <van-pull-refresh v-model="isLoading" @refresh="pullDownRefreshFn">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="There's nothing more！"
        offset="60"
        :immediate-check="false"
        @load="pullUpLoadMoreFn"
      >
        <ArticleList :arr="articleList" :currentProject="currentProject" />
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="projectVisitShow" position="bottom" :round="true" :style="{ height: '60%' }">
      <div class="filter-content">
        <div class="filter-title">My Visit</div>
        <div class="project-content">
          <div v-for="(filterItem, filterIndex) in myVisitList" class="project-item" :class="{'choice-active': activeVisitIndex===filterIndex}" @click="changeVisit(filterItem, filterIndex)">{{ filterItem.nickName }}</div>
        </div>
        <div class="operate-btn">
          <van-button type="primary" @click="resetVisitFilter">重置</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="projectIsShow" position="bottom" :round="true" :style="{ height: '60%' }">
      <div class="filter-content">
        <div class="filter-title">Project</div>
        <div class="project-content">
          <div v-for="(filterItem, filterIndex) in projectList" class="project-item" :class="{'choice-active': activeIndex===filterIndex}" @click="changeProject(filterItem, filterIndex)">{{ filterItem.projectName }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="sortIsShow" position="bottom" :round="true" :style="{ height: '60%' }">
      <div class="filter-sort-content">
        <div class="filter-title">Sort</div>
        <div class="project-content">
          <div v-for="(filterItem, filterIndex) in sortList" class="project-item" :class="{'choice-active': activeSortIndex===filterIndex}" @click="changeSort(filterItem, filterIndex)">{{ filterItem.label }}</div>
        </div>
        <div class="operate-btn">
          <van-button type="primary" @click="resetSortFilter">重置</van-button>
        </div>
      </div>
    </van-popup>
    <DragButton @click="handleAdd">
      <div class="icon-add"><van-icon name="plus" /></div>
    </DragButton>
  </div>
</template>

<script>
import {
  projectAppListApi,
  customerAppListApi
} from '@/api/home'
import { dataScopeUserListApi } from '@/api/user'
import ArticleList from './ArticleList.vue'
import DragButton from '@/components/dragButton.vue'
import { mapState } from 'vuex'
import { Toast } from 'vant'
// 缓存频道数据的大对象
const cacheObj = {}
export default {
  name: 'Home',
  components: {
    ArticleList,
    DragButton
  },
  data () {
    return {
      cacheObj,
      show: false, // 控制 弹出层的可见性
      isLoading: false, // 下拉的加载状态
      loading: false, // 上拉每一页的加载状态
      finished: false, // 上拉是否全部加载完毕
      articleList: [], // 文字列表
      timestamp: undefined, // 记录上一次的 时间戳，需要给后台，做分页用的
      paginationList: {
        page: 1,
        limit: 10,
        total: 0,
        search: ''
      },
      projectList: [],
      currentProject: {
        sortFieldVal: '',
        sortField: '',
        order: '',
        visitLabel: '',
        visitlVal: ''
      },
      projectIsShow: false,
      sortIsShow: false,
      activeIndex: 0,
      activeSortIndex: '',
      // 1升序 0降序
      sortList: [
        { label: 'Last visit time(Ascending)', sortField: 'lastVisitTime', order: 0 },
        { label: 'Last visit timeCity(Descending)', sortField: 'lastVisitTime', order: 1 },
        { label: 'City(Ascending)', sortField: 'city', order: 0 },
        { label: 'City(Descending)', sortField: 'city', order: 1 },
        { label: 'Material deployment(Ascending)', sortField: 'materielCount', order: 0 },
        { label: 'Material deployment(Descending)', sortField: 'materielCount', order: 1 },
        { label: 'ON-SELL(Ascending)', sortField: 'onSellCount', order: 0 },
        { label: 'ON-SELL(Descending)', sortField: 'onSellCount', order: 1 },
      ],
      myVisitList: [],
      activeVisitIndex: '',
      projectVisitShow: false
    }
  },

  watch: {},

  // 路由离开前
  beforeRouteLeave (to, from, next) {
    // 可以访问组件实例 `this`
    // console.log(to) 要去的路由对象
    // console.log(from) 就是当前路由
    // 把滚动的高度 保存到 路由对象的meta字段中的scrollY上
    from.meta.scrollY = window.scrollY
    next()
  },

  async activated () {
    // 一进入Home页面，需要立即 发送请求，拿数据，铺页面
    // this.getArticleList()

    // 页面切回来，自动滚动到 上次 保存的 滚动位置
    // window.scrollTo(x, y)
    this.$nextTick(() => {
      window.scrollTo(0, this.$route.meta.scrollY)
    })
    this.paginationList.page = 1
    this.pullDownRefreshFn()
    this.getMyVisit()
  },
  created() {
    this.getMyVisit()
  },
  computed: {
    ...mapState({
      userInfo: 'myUserInfo'
    })
  },
  methods: {
    handleAdd() {
      console.log('handleAdd')
      this.$router.push('/createStore')
    },
    changeVisit(params, index) {
      this.currentProject.visitLabel = params.nickName
      this.currentProject.visitlVal = params.id
      this.activeVisitIndex = index
      this.pullDownRefreshFn()
      this.projectVisitShow = false
    },
    changeProject(params, index) {
      this.currentProject = params
      this.activeIndex = index
      this.pullDownRefreshFn()
      this.projectIsShow = false
    },
    changeSort(params, index) {
      this.currentProject.sortFieldVal = params.label
      this.currentProject.sortField = params.sortField
      this.currentProject.orderVal = params.order
      this.activeSortIndex = index
      this.pullDownRefreshFn()
      this.sortIsShow = false
    },
    handleFilter() {
      this.projectIsShow = true
    },
    handleSortFilter() {
      this.sortIsShow = true
    },
    handleVisitFilter() {
      if (!this.currentProject.visitLabel) {
        this.myVisitList.find((item, index) => {
          if (item.nickName == this.userInfo.user.nickName) {
            this.activeVisitIndex = index
            return
          }
        })
      }
      this.projectVisitShow = true
    },
    // 获取频道文字列表
    async getArticleList (isPullDown = false) {
      Toast.loading({
        message: 'loading...',
        forbidClick: true,
        duration: 0
      })
      console.log('!this.currentProject.id', !this.currentProject.id, this.currentProject)
      if (!this.currentProject.id) {
        try {
          const projectList = await projectAppListApi()
          this.projectList = projectList.data
          if (this.projectList.length > 0) {
            this.currentProject = this.projectList[0]
          }
        } catch (error) {
          Toast.clear()
        }
        
      }
      const data = {
        ...this.paginationList,
        projectId: this.currentProject.id,
        sortField: this.currentProject.sortField,
        order: this.currentProject.orderVal,
        userIdList: this.currentProject.visitlVal ? [this.currentProject.visitlVal] : ''
      }
      customerAppListApi(data).then(result => {
        Toast.clear()
        if (this.paginationList.page === 1) {
          this.articleList = []
        }
        console.log('customerAppListApi', result)
        // 没有就去请求
        this.paginationList.total = result.data.totalRow
        const storeTotal = localStorage.getItem('storeTotal') || 0
        if (Number(this.paginationList.total) != Number(storeTotal)) {
          localStorage.setItem('storeTotal', this.paginationList.total)
        }
        // 把新数组 与 原来的 数组 进行 合并
        this.articleList = [...this.articleList, ...result.data.list]
        // 把 loading 设置为false，表示 可以进行 下一次请求了
        this.paginationList.page++
        this.loading = false
        console.log('this.articleList.length', this.articleList.length)
        console.log('this.paginationList.total', this.paginationList.total)
        if (this.articleList.length === Number(this.paginationList.total)) {
          this.finished = true
        }
      }).catch(() => {
        Toast.clear()
      })
    },

    // 上拉加载更多
    pullUpLoadMoreFn () {
      // 切换频道的时候，只有articleList 不为空，才触发 上拉加载更多的函数
      if (this.articleList.length > 0) {
        // 1. 发请求 2. 拿数据，合并
        this.getArticleList()
      }
    },
    getMyVisit() {
			dataScopeUserListApi().then(res => {
				this.myVisitList = res.data
			})
		},
    resetVisitFilter() {
      this.currentProject.visitLabel = ''
      this.currentProject.visitlVal = ''
      this.pullDownRefreshFn()
      this.projectVisitShow = false
    },
    resetSortFilter() {
      this.currentProject.sortFieldVal = ''
      this.currentProject.sortField = ''
      this.currentProject.order = ''
      this.activeSortIndex = ''
      this.pullDownRefreshFn()
      this.sortIsShow = false
    },
    // 下拉刷新
    pullDownRefreshFn () {
      console.log('pullDownRefreshFn')
      // 下拉刷新不走缓存，把相应频道缓存的数据 置空 null 和 undefined
      // cacheObj[this.channelId].list = null
      // this.articleList = []
      this.resetPage()
      this.getArticleList(true)
      this.isLoading = false
    },
    resetPage () {
      this.paginationList =  {
        page: 1,
        limit: 10,
        total: 0,
        search: ''
      }
      this.articleList = []
      this.finished = false
      this.loading = false
    },
    getSearchSuggFn () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.timer = null
        this.paginationList.page = 1
        this.getArticleList()
      }, 800)
    }
  }
}
</script>

<style scoped lang="less">
.home-container {
  .van-nav-bar {
    // background-color: #0a4ac1;
    /deep/.van-nav-bar__right {
      width: 100%;
      padding: 0;
    }
  }
  .search-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;
    // background-color: #007bff;
    overflow: hidden;
    /deep/.van-icon-search {
      color: #cdcdcd;
    }
    /*搜索组件*/
    .van-search {
      flex: 1;
    }
  }
  .van-cell-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    top: 46px;
    height: 35px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #dce5f6;
    .channel-total {
      display: flex;
      align-items: center;
      flex: 1;
      .label-name {
        width: 60px;
      }
      .label-value {
        flex: 1;
        width: 66px;
      }
    }
    .channel-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      padding-left: 10px;
      border-left: 1px solid #c8d6f1;
      &:first-child {
        border-left: none;
      }
      .filter-label {
        flex: 1;
      }
    }
  }
  .icon-img {
    width: 20px;
    height: 20px;
  }
  /deep/.van-pull-refresh {
    margin-top: 50px;
  }
}

.logo {
  width: 100px;
  height: 30px;
}
/deep/ .van-tabs__content {
  margin-top: 46px;
}

// 设置 tabs 容器的样式
/deep/ .van-tabs__wrap {
  padding-right: 20px;
  background-color: #fff;
}
// 设置小图标的样式
.moreChannels {
  position: fixed;
  top: 60px;
  right: 8px;
  z-index: 999;
}
.van-popup {
  width: 100%;
  height: 100%;
}
.filter-content,
.filter-sort-content {
  padding: 20px 0;
  font-size: 12px;
  .filter-title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .project-content {
    margin-top: 10px;
    height: 280px;
    overflow: auto;
    .project-item {
      padding: 10px;
      border-bottom: 1px solid #dce5f6;
    }
    .choice-active {
      color: #007bff;
    }
  }
  .operate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    /deep/.van-button {
      width: 120px;
      height: 40px;
      background-color: #dce5f6;
      border: 1px solid #dce5f6;
      border-radius: 8px;
      color: #000;
    }
  }
}
</style>
