<template>
  <div class="article-list__container">
    <ArticleItem
      v-for="(obj, index) in arr"
      :key="obj.art_id"
      :item="obj"
      :currentProject="currentProject"
      :i="index"
      />
  </div>
</template>

<script>
export default {
  name: 'ArticleList',
  props: {
    arr: {
      type: Array,
      required: true
    },
    currentProject: {
      type: Object,
      required: true
    }
  },
  methods: {}
}
</script>

<style>
</style>
