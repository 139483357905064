import Vue from 'vue'

// 聚焦指令
Vue.directive('jujiao', {
  // 当指令所在元素插入到dom中自动触发
  inserted (el) {
    if (['INPUT', 'TEXTAREA'].indexOf(el.tagName) !== -1) {
      el.focus()
    } else {
      let node = el.querySelector('input')
      if (node) {
        node.focus()
        return
      }
      node = el.querySelector('textarea')
      if (node) {
        node.focus()
        return
      }
      throw new Error('请把jujiao指令用在input或textarea上')
    }
  }
})
