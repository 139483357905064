import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login'
import Layout from '@/views/Layout'
import Home from '@/views/Layout/Home'

// 路由懒加载，写成函数的格式 webpack打包的时候，
// 看到这个标识就不会不加载这个js文件
// webpackChunkName 用来 指明 打包后 生成的文件名
// const Search = () => import(/* webpackChunkName: 'Search' */ '@/views/Search')
// const SearchResultList = () => import(/* webpackChunkName: 'SearchResultList' */ '@/views/Search/ResultList')
// const UserEdit = () => import(/* webpackChunkName 'UserEdit' */ '@/views/UserEdit')
// const Chat = () => import(/* webpackChunkName 'Chat' */ '@/views/Chat')
// const ArticleDetail = () => import(/* webpackChunkName 'ArticleDetail' */'@/views/Article/id')
const User = () => import(/* webpackChunkName 'User' */ '@/views/Layout/User')
const VisitChannelDetails = () => import(/* webpackChunkName 'User' */ '@/views/Layout/Home/visitChannelDetails')
const VisitChannelCreate = () => import(/* webpackChunkName 'User' */ '@/views/Layout/Home/visitChannelCreate/index')
const CreateStore = () => import(/* webpackChunkName 'User' */ '@/views/Layout/Home/createStore')
const ChooseLocation = () => import(/* webpackChunkName 'User' */ '@/components/chooseLocation.vue')
const Plan = () => import(/* webpackChunkName 'User' */ '@/views/Layout/Plan')
const PlanLoc = () => import('@/views/Layout/Plan/planLoc')
const MapRoute = () => import('@/views/Layout/Plan/mapRoute')

Vue.use(VueRouter)

const routes = [
  {
    // 根路径 的 重定向
    path: '/',
    redirect: '/layout'// 重定向
  },
  {
    // 登录页
    path: '/login',
    component: Login
  },
  {
    // 首页
    path: '/layout',
    component: Layout,
    redirect: '/layout/home',
    children: [
      {
        // path: '', 或者 重定向
        path: 'home',
        component: Home,
        meta: { // 给路由对象添加额外的信息
          scrollY: 0
        }
      },
      {
        path: 'plan',
        component: Plan
      },
      {
        path: 'user',
        component: User
      }
    ]
  },
  // {
  //   // 搜索页
  //   path: '/search',
  //   component: Search
  // },
  // {
  //   // 搜索页结果页
  //   path: '/search/:keyword', // 动态路由
  //   name: 'resultlist',
  //   component: SearchResultList
  // },
  // {
  //   // 文章详情页
  //   path: '/article',
  //   component: ArticleDetail
  // },
  // {
  //   path: '/user/edit',
  //   component: UserEdit
  // },
  // {
  //   path: '/chat',
  //   component: Chat
  // },
  {
    path: '/visitChannelDetails',
    component: VisitChannelDetails
  },
  {
    path: '/visitChannelCreate',
    component: VisitChannelCreate
  },
  {
    path: '/visitChannelCreate',
    component: VisitChannelCreate
  },
  {
    path: '/createStore',
    component: CreateStore
  },
  {
    path: '/chooseLocation',
    component: ChooseLocation
  },
  {
    path: '/plan/planLoc',
    component: PlanLoc
  },
  {
    path: '/plan/mapRoute',
    component: MapRoute
  }
]

const router = new VueRouter({
  routes
})

export default router
