<template>
  <div id="app">
   <keep-alive :exclude="['SearchResultList']">
      <!-- 一级路由出口 -->
      <router-view/>
   </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="less" scoped>

html { overflow-x: hidden; overflow-y: auto; }
::-webkit-scrollbar {
  display: none;
}
</style>
