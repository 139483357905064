import axios from 'axios'
import store from '@/store'
import router from '@/router'
import request from '@/utils/request'
import { Toast } from 'vant'

import { updateTokenAPI } from '@/api/user'

// 创建axios实例 - 返回一个实例 - 本质还是axios，这样的好处
// 可以根据不同的需求，封装不同的请求 - 灵活
const ajax = axios.create({
  baseURL: process.env.VUE_APP_BASE_API // 统一 配置 基地址
})

// 添加请求拦截器，对 token 做统一处理
ajax.interceptors.request.use(config => {
  // 对 token 做统一处理， 如果vuex中有token，则在请求头中 通过 Authorization字段 携带 给 后台
  if (localStorage.getItem('usToken')) {
    config.headers.Authorization = `${localStorage.getItem('usToken')}`
  }
  config.headers.client_id = 'manage'
  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器 可以对返回数据 或者 错误（token过期）做统一处理
ajax.interceptors.response.use(resp => { // http状态以2xx或3xx开头，执行成功的回调
  // resp.data 直接返回给组件，目的是 让组件 拿到数据 之后 少写 一层 .data
  console.log('resp', resp)
  console.log(![0, 200].includes(resp.data.code))
  if (![0, 200].includes(resp.data.code)) {
    Toast.loading({
      message: resp.data.msg,
      forbidClick: true,
      duration: 2000,
      icon: 'none'
    })
    return Promise.reject(res)
  }
  return resp.data
}, async error => { // http状态以4xx或5xx开头，执行失败的回调
  // 401 就是一个 典型 的 失败，表示 token 过期 或者 无效
  // token过期的解决办法，两种
  // 1. 重新登录
  // console.log(router)//  '/layout/home'
  // 2. token续签（配合refresh_token解决）

  // refresh_token过期了
  if (error.response.data.message === 'refresh_token失效') {
    // 1. 清空vuex 和 本地 的 token，refreshToken
    store.commit('setToken', '')
    // 2. 跳转到登录页重新登录
    router.replace({
      path: '/login?from=' + router.currentRoute.path
    })
  }
  if (error.response.status === 401) {
    // 1. 清空 vuex 中 token
    store.commit('setToken', '')
    // 2. 获取 新的 token
    // const resp = await updateTokenAPI()
    // 3. 更新 vuex 中的 token
    // store.commit('setToken', resp.data.token)
    // config中记录了上次未完成的请求参数信息，比如：method，url，data
    // 把结果return到组件await的地方
    // 4. 继续完成上次未完成的请求
    router.replace({
      path: '/login?from=' + router.currentRoute.path
    })
    // return request(error.config)
  }
  return Promise.reject(error)
})

// 导出 请求函数
export default ajax
