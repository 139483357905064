<template>
  <div
    class="wk-drag-button"
    :style="{
          top: top + 'px',
          left: left + 'px',
          transition: transitionStyle
        }"
    @touchstart="handleTouchStart"
    @touchmove.stop.prevent="handleTouchMove"
    @touchend="handleTouchEnd"
    @click="handleClick">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DragButton',
  props: {
    // 距离上有下左的安全距离
    padding: {
      type: String,
      default: '116 20 70 20'
    },
    // 初始位置距离底部的距离
    bottom: {
      type: Number,
      default: 70
    },

    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      timer: null,
      clientWidth: 0,
      clientHeight: 0,
      itemWidth: 0,
      itemHeight: 0,

      left: 0,
      top: 0,
      transitionStyle: 'none'
    }
  },
  computed: {
    // 安全距离
    safeArea() {
      return this.padding.split(' ')
    },
    dragStyle() {
      return {
        top: this.top + 'px',
        left: this.left + 'px',
        transition: this.transitionStyle
      }
    }
  },
  created() {
    this.initButton()
  },
  methods: {
    initButton() {
      // console.log('navigator.userAgent', navigator.userAgent)
      // const sysInfo = uni.getSystemInfoSync()
      this.clientWidth = document.documentElement.clientWidth + 50
      this.clientHeight = document.documentElement.clientHeight
      // 设置位置
      this.left = (this.clientWidth - this.width - this.safeArea[1])
      this.top = (this.clientHeight - this.height - this.bottom) - 30
    },

    handleClick(evt) {
      this.$emit('click', evt)
    },

    handleTouchStart() {
      this.transitionStyle = 'none'
    },
    handleTouchMove(e) {
      const touch = e.changedTouches[0]
      this.left = touch.clientX - this.width / 2
      this.top = touch.clientY - this.height / 2
    },
    handleTouchEnd(e) {
      this.transitionStyle = 'all 0.3s'
      // 手指放开left位置
      if (this.left > this.clientWidth / 2) {
        this.left = this.clientWidth - this.width - this.safeArea[1]
      } else {
        this.left = this.safeArea[3]
      }
      // 手指放开top位置
      if (this.top < this.safeArea[0]) {
        this.top = this.safeArea[0]
      } else if (this.top > this.clientHeight - this.height - this.safeArea[2]) {
        // 不低于最低
        this.top = this.clientHeight - this.height - this.safeArea[2]
      }
    }
  }
}
</script>

<style lang="less">
.wk-drag-button {
	position: fixed;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s;
	pointer-events: auto;
}
</style>
