<template>
  <div class="login-container">
    <!-- 头部 -->
    <van-nav-bar title="Sign In">
    </van-nav-bar>
    <!-- 表单 -->
    <van-form @submit="onSubmitFn">
      <van-field v-model="formLogin.username"
                 label="username"
                 placeholder=""
                 required
                 :rules="rules.username" />
      <van-field v-model="formLogin.password"
                 type="password"
                 label="password"
                 placeholder=""
                 required
                 :rules="rules.password" />
      <div style="margin: 16px;">
        <van-button :loading="isLoading"
                    block
                    round
                    type="info"
                    native-type="submit"
                    loading-text="加载中...">Sign In</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

import { Notify } from 'vant'
import { mapActions } from 'vuex'
import { encrypt } from '@/utils/rsaEncrypt'
export default {
  name: 'Login',
  data () {
    return {
      isLoading: false, // 登录的加载状态，false 没有加载，true表示加载中
      // 校验规则
      rules: {
        // 手机号的规则
        username: [
          {
            // 1. 必填
            required: true,
            message: 'username is required',
            trigger: 'onChange'
          }
        ],
        // 验证码的规则
        password: [
          {
            // 1. 必填
            required: true,
            message: 'password is required',
            trigger: 'onChange'
          }
        ]
      },
      // 登录表单数据
      formLogin: {
        // 手机号
        username: '',
        // 验证码
        password: '' // 默认只能用246810后台规定了
      }
    }
  },
  methods: {
    ...mapActions(['asyncLoginAction']),
    // 登录按钮的 提交事件 - 只有全部通过校验了才会执行这个函数
    async onSubmitFn () {
      this.isLoading = true
      // 发送请求
      try {
        await this.asyncLoginAction({
          username: this.formLogin.username,
          password: encrypt(this.formLogin.password),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        Notify({ type: 'success', message: '登录成功' })
        this.$router.replace({
          // 登录成功，如果有from表示从这个路径调过来，那么就要让登录成功去这个地址，否则就去首页
          path: '/layout'
          // path: this.$route.query.from || '/layout'
        })
      } catch (err) {
        Notify({ type: 'danger', message: 'Account or Password incorrect' })
      } finally {
        // 这里不管登录成功还是失败，都要将按钮解禁
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.logo {
  width: 100px;
  height: 30px;
}
</style>
